import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductIcon from "../components/productIcon"

const IndexPage = () => {

    const productData = require('../../data/products.json');
    const products = productData.filter(({ }, i) => i < 6);

    return <div class="grid h-screen place-content-center bg-white">
        <div class="text-center">
            <strong class="text-9xl font-black text-gray-200">404</strong>

            <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Uh-oh!
            </h1>

            <p class="mt-4 text-gray-500">We can't find that page.</p>

            <a
                href="/"
                class="mt-6 inline-block rounded bg-primary px-5 py-3 text-sm font-medium text-white hover:opacity-75 focus:outline-none focus:ring"
            >
                Go Back Home
            </a>
        </div>
    </div>

}

export const Head = () => <Seo title="Not Found" />

export default IndexPage
